import logo from './images/logo.png';
import iOSBadge from "./images/iOSBadges/US/Download_on_App_Store/Black_lockup/SVG/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg";
import androidBadge from "./images/androidBadges/en_badge_web_generic.png";
import './App.css';
import "./Home.css";
import { Link } from 'react-router-dom';

const APP_STORE_URL = "https://apps.apple.com/us/app/aurora-compass/id6446504259";
const PLAY_STORE_URL = "https://play.google.com/store/apps/details?id=com.auroracompass";

export const Home = () => {
  return (
    <div className="home-canvas">
      <div className="home-column">
        <div className="logo-row">
          <img
            src={logo}
            alt="logo"
          />
          <div className='logo-text-column'>
            <p className='large-paragraph'>
              {"Aurora"}
            </p>
            <p className='small-paragraph'>
              {"Compass"}
            </p>
          </div>
        </div>

        <div className="badge-row">
          <a
            className='ios-badge'
            href={PLAY_STORE_URL}>
            <img
              src={androidBadge}
              alt='Get it on Google Play'
            />
          </a>
          <a
            className='android-badge'
            href={APP_STORE_URL + "?pt=126178142&ct=homepage_badge&mt=8"}>
            <img
              src={iOSBadge}
              alt='Download on the App Store'
            />
          </a>
        </div>

        {/* <div className="bottom-links">
          <a><Link to='/release-notes'>{"Release Notes"}</Link></a>
          <a><Link to='/sources'>{"Sources"}</Link></a>
        </div> */}
      </div>
    </div>
  );
}
