import React from "react";
import { CSA } from "./images/dataSources/CSA";
import "./Sources.css";
import "./App.css"
import { UOA } from "./images/dataSources/UOA";

// AURORA COMPASS IS MADE POSSIBLE THANKS TO GENEROUS asdf....

export const Sources = () => {
  return (
    <div className="basic-canvas">
      <div className="basic-column">
        <div className="column-container">
          <h3>
            {"Australian Goverment, Geoscience Australia"}
          </h3>
          <img
            src={require("./images/dataSources/GeoscienceAustralia.jpg")}
            alt='Geoscience Australia logo'
            style={{ width: "65%" }}
          />
          <p>
            {"Magnetometers in Alice Springs, Canberra, Casey Station, Charters Towers, Cocos Islands, Gingin, Kakadu, Learmonth, Macquearie Island and Mawson are provided by the Australian Government, Geoscience Australia."}
          </p>
          <i>
            {"Based on geomagnetism.ga.gov.au/real-time-magnetogram by Geoscience Australia which is © Commonwealth of Australia and is provided under a Creative Commons Attribution 4.0 International Licence and is subject to the disclaimer of warranties in section 5 of that licence."}
          </i>
        </div>

        <div className="horizontal-divider-line" />

        <div className="column-container">
          <h3>
            {"Bureau Central de Magnétisme Terrestre (BCMT)"}
          </h3>
          <img
            src={require("./images/dataSources/BCMT.jpg")}
            alt='BCMT logo'
            style={{ width: "70%", padding: "10px", backgroundColor: "#ffffff" }}
          />
          <p>
            {"The magnetometer in Chambon-la-Forêt (Loiret, France) is provided by the Bureau Central de Magnétisme Terrestre (BCMT). It is operated by Institut de Physique du Globe de Paris (IPGP)."}
          </p>
          <i>
            {`The “Bureau Central de Magnétisme Terrestre” (BCMT) is in charge of organizing and coordinating the French observations of the Earth’s magnetic field. Two French institutions are involved in BCMT operations: IPGP and École et Observatoire des Sciences de la Terre (EOST). These two institutions exchange their knowledge and coordinate their actions.`}
          </i>
        </div>

        <div className="horizontal-divider-line" />

        <div className="column-container">
          <h3>
            {"CARISMA magnetometer network"}
          </h3>

          <div className="row-container" style={{ justifyContent: "space-evenly", gap: 0 }}>
            <img
              src={require("./images/dataSources/Carisma.jpg")}
              alt='CARISMA logo'
              style={{ width: "25%" }}
            />
            <div style={{ width: "15%" }}>
              <CSA />
            </div>
            <div style={{ width: "42%", alignSelf: "center" }}>
              <UOA />
            </div>
          </div>

          <p>
            {"All the Canadian magnetometers Aurora Compass uses are part of the CARISMA (Canadian Array for Realtime Investigations of Magnetic Activity) magnetometer network. They are operated by the University of Alberta. The program is funded by the Canadian Space Agency."
            }
          </p>
          <i>
            {"The authors thank I.R. Mann, D.K. Milling and the rest of the CARISMA team for data. CARISMA is operated by the University of Alberta, funded by the Canadian Space Agency.\n\nMann, I. R., et al. (2008), The upgraded CARISMA magnetometer array in the THEMIS era, Space Sci. Rev., 141, 413–451, doi:10.1007/s11214-008-9457-6."}
          </i>
        </div>

        <div className="horizontal-divider-line" />

        <div className="column-container">
          <h3>
            {"ESA and NASA"}
          </h3>
          <div className="column-container">
            <div className="row-container">
              <img
                src={require("./images/dataSources/ESA.png")}
                alt='ESA logo'
                style={{ width: "28%" }}
              />
              <img
                src={require("./images/dataSources/NASA.png")}
                alt='NASA logo'
                style={{ width: "33%" }}
              />
            </div>
            <div className="column-container">
              <p>
                {"Solar images from the Solar Dynamics Observatory (SDO) and the Solar & Heliospheric Observatory (SOHO) are provided by ESA and NASA."}
              </p>
              <i>
                {"Courtesy of NASA/SDO and the AIA, EVE, and HMI science teams. SOHO is a project of international cooperation between ESA and NASA."}
              </i>
            </div>
          </div>
        </div>

        <div className="horizontal-divider-line" />

        <div className="column-container">
          <h3>
            {"IMAGE magnetometer network"}
          </h3>
          <img
            src={require("./images/dataSources/IMAGE_logo_2.png")}
            alt='IMAGE logo'
            style={{ width: "65%", marginTop: "-6px", marginBottom: "-6px" }}
          />
          <img
            src={require("./images/dataSources/IMAGE.png")}
            alt='IMAGE logo'
            style={{ width: "96%", backgroundColor: "#ffffff", padding: "8px" }}
          />
          <p>
            {"Most of the European magnetometers Aurora Compass uses are part of the IMAGE (International Monitor for Auroral Geomagnetic Effects) magnetometer network. IMAGE consists of 61 magnetometer stations maintained by 10 institutes from Finland, Germany, Norway, Poland, Russia, Sweden, Denmark, and Iceland. One of the collaborators, German Research Centre for Geosciences (GFZ), operates also a magnetometer located Neumayer, Antarctica."
            }
          </p>
          <i>
            {"We thank the institutes who maintain the IMAGE Magnetometer Array: Tromsø Geophysical Observatory of UiT the Arctic University of Norway (Norway), Finnish Meteorological Institute (Finland), Institute of Geophysics Polish Academy of Sciences (Poland), GFZ German Research Centre for Geosciences (Germany), Geological Survey of Sweden (Sweden), Swedish Institute of Space Physics (Sweden), Sodankylä Geophysical Observatory of the University of Oulu (Finland), DTU Technical University of Denmark (Denmark), and Science Institute of the University of Iceland (Iceland). The provisioning of data from AAL, GOT, HAS, NRA, VXJ, FKP, ROE, BFE, BOR, HOV, SCO, KUL, and NAQ is supported by the ESA contracts number 4000128139/19/D/CT as well as 4000138064/22/D/KS."}
          </i>
        </div>

        <div className="horizontal-divider-line" />

        <div className="column-container">
          <h3>
            {"National Oceanic and Atmospheric Administration's Space Weather Prediction Center (NOAA SWPC)"}
          </h3>
          <div className="row-container">
            <img
              src={require("./images/dataSources/NOAA.jpg")}
              alt='NOAA logo'
              style={{ width: "22%", backgroundColor: "#ffffff", padding: "3px" }}
            />
            <p>
              {"The forecasted aurora probability as well as map view's forecast is based on NOAA's ovation model. NOAA also provides solar wind data, kp forecasts, solar regions, flare probabilities, daily sunspot numbers and activity reports."}
            </p>
          </div>
        </div>

        <div className="horizontal-divider-line" />

        <div className="column-container">
          <h3>
            {"SuperMAG"}
          </h3>
          <img
            src={require("./images/dataSources/SuperMAG.png")}
            alt='SuperMAG logo'
            style={{ width: "60%", backgroundColor: "#ffffff", padding: "7px", paddingRight: 0 }}
          />
          <p>
            {"Magnetometer raw data is converted into geomagnetic activity indices with a custom-made converter. It has been made by using historical magnetometer data downloaded from SuperMAG."}
          </p>
          <i>
            {"Gjerloev, J. W. (2012), The SuperMAG data processing technique, J. Geophys. Res., 117, A09213, doi:10.1029/2012JA017683."}
          </i>
        </div>

        <div className="horizontal-divider-line" />

        <div className="column-container">
          <h3>
            {"United States Geological Survey (USGS)"}
          </h3>
          <img
            src={require("./images/dataSources/USGS.png")}
            alt='USGS logo'
            style={{ width: "45%", backgroundColor: "#000000", padding: "6px" }}
          />
          <p>
            {"Magnetometers in Boulder, Barrow, College, Deadhorse, Fredericksburg, Fresno, Guam, Honolulu, Newport, San Juan, Shumagin, Sitka, Stennis and Tucson are provided by the USGS Geomagnetic Program."}
          </p>
        </div>

        <div className="horizontal-divider-line" />

        <div className="column-container">
          <h3>
            {"University of Alaska, Geophysical Institute"}
          </h3>
          <img
            src={require("./images/dataSources/UAF.png")}
            alt='UAF logo'
            style={{ width: "60%" }}
          />
          <p>
            {"Magnetometers in Eagle, Fort Yukon, Gakona, Kenai, Poker Flat, Toolik Lake and Trapper Creek are provided by University of Alaska."}
          </p>
          <i>
            {"Magnetometer data, Geophysical Institute, UAF 2023. Retrieved from Research Computing Systems."}
          </i>
        </div>
      </div>
    </div>
  )
}