import React from "react";

export const CSA = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.711 39.711">
            <path
                fill="#0060a9"
                d="M641.696 44.125c10.831 0 19.613-8.78 19.613-19.612S652.526 4.9 641.696 4.9s-19.613 8.782-19.613 19.613 8.782 19.612 19.613 19.612"
                transform="translate(-621.84 -4.657)"
            ></path>
            <path
                fill="none"
                stroke="#FFF"
                strokeWidth="0.486"
                d="M641.696 44.125c10.831 0 19.613-8.78 19.613-19.612S652.526 4.9 641.696 4.9s-19.613 8.782-19.613 19.613 8.782 19.612 19.613 19.612z"
                transform="translate(-621.84 -4.657)"
            ></path>
            <path
                fill="#FFF"
                d="M631.17 33.855c-.23-.212-.535-.392-.915-.392-.502 0-.979.335-1.058.759-.08.43.294.76.798.76.385 0 .72-.14 1.03-.362l-.321 1.458c-.291.109-.925.154-1.228.154-1.317 0-2.187-.863-1.978-1.974.212-1.136 1.445-2.046 2.8-2.046.382 0 .765.057 1.1.16l-.228 1.483M631.868 34.775l.076.057c.264.196.595.357.982.357.167 0 .44-.057.473-.234.034-.175-.185-.19-.332-.216l-.329-.051c-.637-.099-1.129-.424-1.015-1.035.174-.925 1.271-1.441 2.278-1.441.528 0 .992.108 1.402.34l-.641.998c-.256-.165-.559-.295-.921-.295-.147 0-.37.048-.4.208-.028.144.178.165.302.19l.366.072c.688.135 1.142.455 1.02 1.118-.176.93-1.268 1.389-2.27 1.389-.582 0-1.23-.134-1.712-.355l.721-1.102M638.142 34.76l-.069-.688c-.019-.165-.017-.335-.016-.502h-.063l-.527 1.19h.675zm-1.377 1.41h-1.78l2.34-3.896h1.858l.922 3.896h-1.78l-.063-.486h-1.27l-.227.486M645.892 34.76l-.068-.688c-.017-.165-.017-.335-.017-.502h-.061l-.529 1.19h.675zm-1.376 1.41h-1.779l2.339-3.896h1.859l.92 3.896h-1.78l-.062-.486h-1.271l-.226.486M648.747 34.775l.075.057c.264.196.596.357.983.357.165 0 .44-.057.473-.234.033-.175-.185-.19-.334-.216l-.328-.051c-.639-.099-1.129-.424-1.015-1.035.172-.925 1.27-1.441 2.277-1.441.526 0 .993.108 1.403.34l-.641.998c-.257-.165-.558-.295-.92-.295-.148 0-.37.048-.402.208-.027.144.178.165.303.19l.367.072c.686.135 1.141.455 1.018 1.118-.175.93-1.268 1.389-2.268 1.389-.584 0-1.235-.134-1.714-.355l.723-1.102M656.146 33.855c-.231-.212-.535-.392-.915-.392-.504 0-.978.335-1.058.759-.08.43.294.76.797.76.387 0 .72-.14 1.032-.362l-.322 1.458c-.29.109-.925.154-1.226.154-1.32 0-2.19-.863-1.982-1.974.215-1.136 1.446-2.046 2.803-2.046.38 0 .762.057 1.1.16l-.229 1.483M629.346 26.023c1.071-1.679 2.39-3.215 6.351-7.348 2.753-2.87 8.57-7.912 11.675-5.947.775.49 1.457 2.209 1.457 2.209l-.35.366c-.124-.56-.617-1.236-.986-1.575-1.85-1.69-6.91.902-11.308 5.939-3.56 4.075-3.277 3.59-4.812 5.25l-2.027 1.106"
                transform="translate(-621.84 -4.657)"
            ></path>
            <path
                fill="#FFF"
                d="M642.383 22.76l-.748-15.026-.746 15.026h1.494"
                transform="translate(-621.84 -4.657)"
            ></path>
            <path
                fill="#FFF"
                d="M642.501 22.477l-8.805-4.757 6.604 4.757h2.201"
                transform="translate(-621.84 -4.657)"
            ></path>
            <path
                fill="#FFF"
                d="M640.771 22.477l8.805-4.757-6.604 4.757h-2.201"
                transform="translate(-621.84 -4.657)"
            ></path>
            <path
                fill="#FFF"
                d="M642.03 22.515l-1.769-2.79.629 2.79h1.14"
                transform="translate(-621.84 -4.657)"
            ></path>
            <path
                fill="#FFF"
                d="M641.243 22.515l1.768-2.79-.628 2.79h-1.14"
                transform="translate(-621.84 -4.657)"
            ></path>
            <path
                fill="#FFF"
                d="M641.675 22.554l-12.184-.942 12.145-.354.039 1.296"
                transform="translate(-621.84 -4.657)"
            ></path>
            <path
                fill="#FFF"
                d="M641.616 22.554l12.185-.942-12.145-.354-.04 1.296M648.828 15.913a.445.445 0 100-.891.445.445 0 000 .89"
                transform="translate(-621.84 -4.657)"
            ></path>
            <path
                fill="#FFF"
                d="M648.985 15.467c0-.683-.07-1.24-.156-1.24-.085 0-.156.557-.156 1.24 0 .685.07 1.24.156 1.24s.156-.555.156-1.24"
                transform="translate(-621.84 -4.657)"
            ></path>
            <path
                fill="#FFF"
                d="M648.828 15.312c-.684 0-1.238.07-1.238.155 0 .086.554.156 1.238.156s1.239-.07 1.239-.156c0-.085-.555-.155-1.239-.155M657.98 30.094c-3.624-5.295-9.49-8.45-16.159-8.45-6.776 0-12.782 3.178-16.391 8.616 4.025-4.208 9.82-7.418 16.284-7.418 6.424 0 12.244 3.088 16.266 7.252M636.946 8.107c.29 0 .525.208.525.465s-.235.465-.525.465-.526-.208-.526-.465.236-.465.526-.465"
                transform="translate(-621.84 -4.657)"
            ></path>
            <path
                fill="#FFF"
                d="M636.922 8.794c.54 0 .979-.081.979-.18 0-.1-.439-.181-.979-.181s-.978.081-.978.181c0 .099.438.18.978.18"
                transform="translate(-621.84 -4.657)"
            ></path>
            <path
                fill="#FFF"
                d="M637.15 8.593c0-.54-.08-.978-.181-.978-.1 0-.18.438-.18.978s.08.977.18.977c.101 0 .181-.437.181-.977"
                transform="translate(-621.84 -4.657)"
            ></path>
            <path
                fill="#ef3e33"
                d="M641.836 31.875s-.059-1.45-.059-1.595c0-.145.122-.195.267-.165.143.034 1.502.31 1.502.31s-.105-.293-.177-.512c-.072-.218.022-.255.072-.301l1.519-1.313-.148-.072-.093-.047c-.147-.068-.122-.19-.1-.27.023-.082.29-.927.29-.927s-.66.141-.807.165c-.117.016-.158-.039-.195-.12l-.19-.451s-.773.857-.792.872c-.146.136-.294.033-.266-.126.026-.145.398-1.86.398-1.86s-.41.246-.547.326c-.135.083-.208.069-.281-.062-.072-.132-.582-1.15-.582-1.15s-.508 1.018-.578 1.15c-.074.13-.148.145-.28.062-.14-.08-.55-.326-.55-.326s.373 1.715.398 1.86c.028.159-.118.262-.266.126-.017-.015-.792-.872-.792-.872s-.154.37-.19.45c-.034.082-.077.137-.195.12-.145-.023-.805-.164-.805-.164s.266.845.288.927c.023.08.047.202-.097.27l-.094.047-.149.072s1.468 1.268 1.518 1.313c.051.046.145.083.075.3-.075.22-.178.512-.178.512s1.357-.275 1.5-.309c.145-.03.267.02.267.165 0 .144-.058 1.595-.058 1.595h.375"
                transform="translate(-621.84 -4.657)"
            ></path>
            <path
                fill="none"
                stroke="#FFF"
                strokeWidth="0.209"
                d="M641.836 31.875s-.059-1.45-.059-1.595c0-.145.122-.195.267-.165.143.034 1.502.31 1.502.31s-.105-.293-.177-.512c-.072-.218.022-.255.072-.301l1.519-1.313-.148-.072-.093-.047c-.147-.068-.122-.19-.1-.27.023-.082.29-.927.29-.927s-.66.141-.807.165c-.117.016-.158-.039-.195-.12l-.19-.451s-.773.857-.792.872c-.146.136-.294.033-.266-.126.026-.145.398-1.86.398-1.86s-.41.246-.547.326c-.135.083-.208.069-.281-.062-.072-.132-.582-1.15-.582-1.15s-.508 1.018-.578 1.15c-.074.13-.148.145-.28.062-.14-.08-.55-.326-.55-.326s.373 1.715.398 1.86c.028.159-.118.262-.266.126-.017-.015-.792-.872-.792-.872s-.154.37-.19.45c-.034.082-.077.137-.195.12-.145-.023-.805-.164-.805-.164s.266.845.288.927c.023.08.047.202-.097.27l-.094.047-.149.072s1.468 1.268 1.518 1.313c.051.046.145.083.075.3-.075.22-.178.512-.178.512s1.357-.275 1.5-.309c.145-.03.267.02.267.165 0 .144-.058 1.595-.058 1.595h.375z"
                transform="translate(-621.84 -4.657)"
            ></path>
        </svg>
    );
}
