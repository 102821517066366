import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Home } from './Home';
import { Sources } from './Sources';
import { ReleaseNotes } from './ReleaseNotes';


const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/sources" element={<Sources />} />
        <Route path="/release-notes" element={<ReleaseNotes />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App;
